


































































import { Component, Prop, Vue } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";

@Component
export default class CalendarView extends Request {
  @Prop() readonly events;
  focus = "";
  today = "";
  start: Start | null = null;
  end: End | null = null;
  selectedEvent = {};
  selectedElement = null;
  selectedOpen = false;
  get title() {
    const { start, end } = this;
    if (!start || !end) {
      return "";
    }
    const startMonth = this.monthFormatter(start);
    const startYear = start.year;
    return `${translate(startMonth.toLowerCase())} ${startYear}`;
  }
  get monthFormatter() {
    return this.calendarInstance.getFormatter({
      timeZone: "UTC",
      month: "long",
    });
  }
  mounted() {
    this.calendarInstance.checkChange();
  }
  viewDay({ date }: { date: string }) {
    this.focus = date;
  }
  getEventColor(event: Event) {
    return event.color;
  }
  setToday() {
    this.focus = this.today;
  }
  prev() {
    this.calendarInstance.prev();
  }
  next() {
    this.calendarInstance.next();
  }
  showEvent({ nativeEvent, event }: { nativeEvent: any; event: Event }) {
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };
    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }
    nativeEvent.stopPropagation();
  }
  updateRange({ start, end }: { start: Start; end: End }) {
    this.start = start;
    this.end = end;
  }
  nth(d: number) {
    return d > 3 && d < 21
      ? "th"
      : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
  }
  rnd(a: number, b: number) {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }
  formatDate(a: Date, withTime: boolean) {
    return withTime
      ? `${a.getFullYear()}-${
          a.getMonth() + 1
        }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
      : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
  }
  private get calendarInstance(): Vue & {
    prev: () => void;
    next: () => void;
    checkChange: () => void;
    getFormatter: (format: any) => any;
  } {
    return this.$refs.calendar as Vue & {
      prev: () => void;
      next: () => void;
      checkChange: () => void;
      getFormatter: (format: any) => any;
    };
  }
}
export type Start = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
};
export type End = {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
};
export type Event = {
  name: string;
  start: string;
  end: string;
  color: string;
};
