






















































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { IChannel } from "../../types/Channel";
import Whatsapp from "./channels/Whatsapp.vue";
import Telegram from "./channels/Telegram.vue";
import WebChat from "./channels/WebChat.vue";
import { AttendanceChannel, IBroker } from "../../types/AttendanceChannel";
import { translate } from "../../plugins/i18n";
@Component({
  components: {
    Whatsapp,
    Telegram,
    WebChat,
  },
})
export default class AttendanceChannelInput extends Request {
  @Prop() header: string;
  @Prop() entity: AttendanceChannel;
  @Prop() list: IChannel[];
  @Prop() loadData: () => void;
  @Prop() brokerList: IBroker[];

  dialog: boolean = false;
  channelTypeList: any = [];
  channelType: string = "";

  closeDialog(value: any) {
    this.dialog = value;
  }
  updateSelect(value) {
    this.channelType = value;
  }
  mounted() {
    this.channelTypeList = [
      { id: 1, name: `${translate("whatsapp")}` },
      { id: 3, name: `${translate("webChat")}` },
    ];

    this.channelType = this.entity.referenceName;

  }
}
