











































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import { mapGetters } from "vuex";
import { AttendanceChannel } from "../../../types/AttendanceChannel";
import { IBroker } from "../../../types/broker";
// import FB from "fb";
declare const FB: any;
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Whatsapp extends Request {
  @Prop() readonly header: string;
  @Prop() readonly entity: AttendanceChannel;
  @Prop() readonly list: AttendanceChannel[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly channel: string;
  @Prop() readonly brokerList: IBroker[];

  getUser: any;
  editorUserId: number = null;
  entityChannel: AttendanceChannel = {};
  entityChannelDetail: any = {};
  broker: IBroker = {};
  closeDialog() {
    return this.$emit("update-dialog", false);
  }
  async onSave() {
    this.closeDialog();
    return await this.newList();
  }

  load(): void {
    if (this.entity) {
      this.entityChannel = { ...this.entity };
    }
    if (this.entity.channelDetails) {
      this.entityChannelDetail = { ...this.entity.channelDetails[0] };
    }
  }
  async newList() {
    delete this.entityChannel["createdAt"];
    this.entityChannel.editorUserId = this.editorUserId;
    this.entityChannelDetail.referenceName = this.channel;
    this.entityChannel.active = true;
    this.entityChannel.channelDetail = this.entityChannelDetail;
    this.entityChannel.brokerId = this.broker.id;

    const channel = await this.save(
      this.paths.channelCompletePath,
      this.entityChannel
    );

    this.loadData();
    this.entityChannel = {};
    this.entityChannelDetail = {};
    this.$emit("update-select", null);
    return channel;
  }

  async mounted() {
    this.load();

    const user = this.getUser();
    this.editorUserId = user.id;

    FB.init({
      appId: "531510235305653",
      autoLogAppEvents: true,
      xfbml: true,
      version: "v18.0",
      https: false,
    });
  }
  launchWhatsAppSignup() {
    FB.login(
      (response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          console.log(accessToken);
        } else {
          console.log(response)
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "345416175041230",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
        },
      }
    );
  }
  get missingName() {
    return !this.entityChannel.name;
  }
  get missingTokenAuthorization() {
    return !this.entityChannelDetail.tokenAuthorization;
  }
  get missingPhoneNumber() {
    return !this.entityChannelDetail.phoneNumber;
  }
  get missingPhoneId() {
    return !this.entityChannelDetail.phoneId;
  }
  get saveDisabled() {
    return (
      this.missingName ||
      this.missingTokenAuthorization ||
      this.missingPhoneNumber ||
      this.missingPhoneId
    );
  }
}
