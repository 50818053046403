


























































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import BreakInput from "./BreakInput.vue";
import { translate } from "../../plugins/i18n/index";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import DeleteWithChildren from "../../components/DeleteWithChildren.vue";
import BreaksAgentsDialog from "../../components/BreaksAgentsDialog.vue";

@Component({
  components: {
    List,
    BreakInput,
    BaseBreadcrumb,
    ConfirmDelete,
    BreaksAgentsDialog,
    DeleteWithChildren,
  },
})
export default class BreakList extends Request {
  deletePath: string = "";
  title = `${translate("break")}`;
  breadcrumbs = [
    {
      text: `${translate("break")}`,
      disabled: true,
    },
  ];
  tagStatus = "status";
  list: any = [];
  haveUser: any = [];
  header = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    { text: `${translate("start")}`, align: "center", value: "start" },
    { text: `${translate("end")}`, align: "center", value: "end" },
    { text: `${translate("status")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  isCharged = false;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-calendar",
    titlePage: `${translate("break")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  async loadData() {
    this.isCharged = false;
    this.deletePath = this.paths.breakPath;
    const result = await this.get(this.paths.breakPath);
    this.list = result[0];
    this.list.map((item) => {
      item.users = [];
    });
    const userAgentBreakList = await this.get(this.paths.userAgentBreak);
    this.list.map((item) => {
      item.users = [];
      userAgentBreakList[0].map((breaks) => {
        if (breaks.breakId === item.id) {
          item.users.push(breaks.userAgent);
        }
      });
    });
    this.isCharged = true;
  }
  mounted() {
    this.loadData();
  }
}
