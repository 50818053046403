

























































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import CalendarInput from "./CalendarInput.vue";
import { Calendar } from "../../types/Calendar";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import CalendarView from './CalendarView.vue'
import {
  InputPopup,
  Input,
} from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { paths } from "../../services/apiPaths";
import ConfirmDelete from '../../components/ConfirmDelete.vue'
import { format, parseISO } from "date-fns";
@Component({
  components: {
    List,
    CalendarInput,
    BaseBreadcrumb,
    InputPopup,
    Input,
    CalendarView,
    ConfirmDelete,
  },
})
export default class Calendarlist extends Request {
  path = paths.calendarPath;
  title = `${translate("calendar")}`;
  breadcrumbs = [
    {
      text: `${translate("calendar")}`,
      disabled: true,
    },
  ];
  deletePath: string = "";

  header = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-calendar",
    titlePage: `${translate("calendar")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: Calendar[] = [];

  async loadData() {
    this.deletePath = this.paths.calendarPath;
    const result = await this.get(this.paths.calendarPath);
    this.list = result[0];
    if (result) {
      this.list.map((item) => {
        item.event.map((element) => {
          item.active = element.active;
          item.createdAt = format(parseISO(element.createdAt), "dd/MM/yyyy");
        });
      });
    }
  }
  async mounted()  {
    this.loadData();
  }
}
