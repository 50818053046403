











import { Calendar } from "@/types/Calendar";
import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import CalendarWidget from "./CalendarWidget.vue";

@Component({
  components: { CalendarWidget },
})
export default class CalendarView extends Request {
  @Prop() readonly entity: Calendar;
  dialog = false;
  formatedEvents = [];
  listAgents = [];
  events = [];
  convertcalendarEvents() {
    this.formatedEvents = this.events.map((item) => {
     
      return {
        name: item.name,
        start: new Date(item.dateStart).toISOString().substring(0, 10),
        end: new Date(item.dateEnd).toISOString().substring(0, 10),
        color: "primary",
        participants: item.eventParticipants.map((participant) => this.listAgents.find((agent) => participant.userId == agent.userId))
      };
    });
  }
  async mounted()  {
    const users = await this.get(this.paths.userAgentPath);
    this.events = await this.get(
      this.paths.eventsByParam + `?calendarId=${this.entity.id}`
    );
    this.listAgents = users[0];
    this.convertcalendarEvents();
  }
}
