


































































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ChannelInput from "./ChannelInput.vue";
import { AttendanceChannel, IBroker } from "../../types/AttendanceChannel";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import { HeaderItem } from "../../types/headeritem";
import { Breadcrumb } from "../../types/breadcrumb";

import {
  InputPopup,
  Input,
} from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { format, parseISO } from "date-fns";
@Component({
  components: {
    List,
    ChannelInput,
    BaseBreadcrumb,
    InputPopup,
    Input,
    ConfirmDelete,
  },
})
export default class ChannelList extends Request {
  html: string = "";
  title: string = `${translate("attendancyChannel")}`;
  checkChannel: string = "";
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("attendancyChannel")}`,
      disabled: true,
    },
  ];
  brokerList: IBroker[];
  dialog: boolean = false;
  deletePath: string = "";
  header: HeaderItem[] = [
    { text: `${translate("countName")}`, align: "start", value: "name" },
    {
      text: `${translate("attendencyChannel")}`,
      align: "center",
      value: "referenceName",
    },
    { text: `${translate("phone")}`, align: "center", value: "phone" },
    {
      text: `${translate("phoneId")}`,
      align: "center",
      value: "channelDetails[0].phoneId",
    },

    {
      text: `${translate("creationDate")}`,
      align: "center",
      value: "createdAt",
    },
    { text: `${translate("status")}`, align: "center", value: "active" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-cellphone-text",
    titlePage: `${translate("attendancyChannel")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: AttendanceChannel[] = [];
  async loadData() {
    const broker = await this.get(this.paths.brokerPath);
    this.brokerList = broker[0];
    const result = await this.get(this.paths.channelPath);
    this.deletePath = this.paths.channelPath;
    this.list = result[0];
    this.list.map((item) => {
      item.channelDetails.map((detail) => {
        item.phone = detail.phoneNumber;
        item.referenceName = detail.referenceName;
      });
      return (item.createdAt = format(parseISO(item.createdAt), "dd/MM/yyyy"));
    });
  }

  mounted() {
    this.loadData();
  }
  copyFloatWebChat() {
    this.html = ``;
    navigator.clipboard.writeText(this.html);
  }
  copyWhatsappButton(phone) {
    phone = phone.replace(/-/g, "");
    phone = phone.replace(/[{()}]/g, "");
    phone = phone.replace(/\s/g, "");
    const url = `https://api.whatsapp.com/send?phone=${phone}&submissionGuid=960fc0bb-f277-40df-8b6e-ca91ed07bdd3`;
    this.html = `<a href="${url}" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a>`;

    navigator.clipboard.writeText(this.html);
  }
}
