









































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import Request from "../../../services/request";
import { ChannelData } from "../../../types/telegram";
import { IChannel } from "../../../types/Channel";
import { AttendanceChannel } from "../../../types/AttendanceChannel";

@Component({
  components: {
    InputPopup,
  },
})
export default class Telegram extends Request {
  @Prop() readonly header: string;
  @Prop() readonly entity: AttendanceChannel;
  @Prop() readonly list: IChannel[];
  @Prop() readonly loadData: () => void;
  brokerList: any = [];
  broker: number = null;
  name: string = "";
  appName: string = "";
  appLogoUrl: string = "";
  localEntity: AttendanceChannel = {};
  active: boolean = true;

  closeDialog() {
    return this.$emit("update-dialog", false);
  }
  async onSave() {
    this.closeDialog();
    return await this.newList();
  }

  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  async newList() {
    const result = await this.save(this.paths.channelPath, this.localEntity);
    this.loadData();
    return result;
  }
  channelData: ChannelData = {};
  async mounted() {
    this.load();

    this.channelData.destinyTypeList = [
      { id: 1, name: "Flow" },
      { id: 2, name: "Flow" },
      { id: 3, name: "Flow" },
    ];
    this.channelData.destinyList = [
      { id: 1, name: "Flow 1" },
      { id: 2, name: "Flow 2" },
      { id: 3, name: "Flow 3" },
    ];
    this.channelData.satisfactionSurveyList = [
      { id: 1, name: "Pesquisa 1" },
      { id: 2, name: "Pesquisa 2" },
      { id: 3, name: "Pesquisa 3" },
    ];
    this.channelData.apiTypeList = [
      { id: 1, name: "Matrix 1" },
      { id: 2, name: "Matrix 2" },
      { id: 3, name: "Matrix 3" },
    ];
    this.channelData.verticalList = [
      { id: 1, name: "Sim" },
      { id: 2, name: "Não" },
    ];

    const result = await this.get(this.paths.brokerPath);
    this.brokerList = result[0];
    console.log( this.brokerList)
    this.name = this.entity.name;
    this.appName = this.entity.appName;
    this.appLogoUrl = this.entity.appLogoUrl;
    this.broker = this.entity.brokerId;
    this.active = this.entity.active;
  }
}
