














































































































































import { Component, Prop } from "vue-property-decorator";
import { IBreak } from "../../types/IBreak";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import { mapGetters } from "vuex";

@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class BreakInput extends Request {
  @Prop() readonly entity: IBreak;
  @Prop() readonly list: IBreak[];
  @Prop() readonly loadData: any;
  localEntity: any = {};
  getUser: any;
  editorUserId: number = null;
  menuStart: boolean = false;
  menuEnd: boolean = false;
  dialog: boolean = false;
  header = `${translate("break")}`;
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async onSave()  {
    this.localEntity.editorUserId = this.editorUserId;
    const result = await this.save(this.paths.breakPath, this.localEntity);
  
    this.loadData();
    this.dialog = false;
    return result;
  }
  mounted() {
    this.load();
    const user = this.getUser();
    this.editorUserId = user.id;
  }

  get missingName() {
    return !this.localEntity.name;
  }
  get missingStart() {
    return !this.localEntity.start;
  }
  get missingEnd() {
    return !this.localEntity.end;
  }
  get saveDisabled() {
    return this.missingName || this.missingStart || this.missingEnd;
  }
}
