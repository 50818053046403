



































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import { mapGetters } from "vuex";
import { IBroker } from "../../../types/webcchat";
import { AttendanceChannel } from "../../../types/AttendanceChannel";
import { IUser } from "../../../types/User";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class WebChat extends Request {
  @Prop() readonly header: string;
  @Prop() readonly entity: AttendanceChannel;
  @Prop() readonly list: AttendanceChannel[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly channel: string;
  brokerList: IBroker[] = [];
  entityChannelDetail: any = {};
  entityChannel: AttendanceChannel = {};
 
  s3Result: any = {};
  getUser!: () => IUser;
  editorUserId: number = null;
  closeDialog() {
    return this.$emit("update-dialog", false);
  }
  async onSave() {
    this.closeDialog();
    return await this.newList();
  }
  load(): void {
    if (this.entity) {
      this.entityChannel = { ...this.entity };
    }
    if (this.entity.channelDetails) {
      this.entityChannelDetail = { ...this.entity.channelDetails[0] };
    }
  }

  async newList() {
    delete this.entityChannel["createdAt"];

    this.entityChannel.editorUserId = this.editorUserId;
    this.entityChannelDetail.referenceName = this.channel;
    this.entityChannel.active = true;
    this.entityChannel.channelDetail = this.entityChannelDetail;

    const channel = await this.save(
      this.paths.channelCompletePath,
      this.entityChannel
    );

    this.loadData();
    this.entityChannel = {};
    this.entityChannelDetail = {};
    this.$emit("update-select", null);
    return channel;
  }
  async mounted() {
    this.load();
    const result = await this.get(this.paths.brokerPath);
    this.brokerList = result[0];
    this.entityChannel.brokerId = this.brokerList[0].id;
    const user = this.getUser();
    this.editorUserId = user.id;
  }
  async createFile(file) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("file", file);
    const request = await this.post(this.paths.storagePath, data);

    return (this.s3Result = request);
  }
  async saveTopImage(photo) {
    const reader = new FileReader();
    reader.onload = async (event) => {
      await this.createFile(event.target.result);

      this.entityChannelDetail.floatingChatImage = this.s3Result.s3.body;
      const idToSave = this.entityChannel.id;

      const results3 = await this.put(
        this.paths.channelDetailPath + idToSave,
        this.entityChannelDetail
      );

      return results3;
    };
    reader.readAsDataURL(photo);
  }
  get missingName() {
    return !this.entityChannel.name;
  }
  get missingIdentifier() {
    return !this.entityChannelDetail.identifierUrl;
  }
  get isRequiredParameters() {
    return !this.entityChannelDetail.isRequiredParameters;
  }
  get saveDisabled() {
    return this.missingName || this.missingIdentifier;
  }
}
