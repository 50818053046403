



































































import { Component, Prop } from "vue-property-decorator";
import { Calendar } from "../../types/Calendar";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import { mapGetters } from "vuex";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class CalendarInput extends Request {
  @Prop() readonly entity: Calendar;
  @Prop() readonly list: Calendar[];
   @Prop() readonly loadData: any;
  localEntity: any = {};
  getUser: any;
  editorUserId: number = null;
  dialog: boolean = false;

  
  async onSave()  {
    this.localEntity.editorUserId = this.editorUserId;
    const result = await this.save(this.paths.calendarPath, {
      ...this.localEntity,
      ownerId: 1,
    });
    this.loadData();
    this.dialog = false;
    return result;
  }
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  mounted() {
    this.load();
    const user = this.getUser();
    this.editorUserId = user.id;
  }
  header = `${translate("calendar")}`;

  get missinName() {
    return !this.localEntity.name;
  }
  get saveDisabled() {
    return this.missinName;
  }
}
