var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.entity && _vm.entity.id)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","title":"Editar"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn__content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","title":"Criar"}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.entity.id ? _vm.$t("edit") + _vm.header : _vm.$t("add") + _vm.header)+" "),_c('v-spacer'),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v("mdi-close")])],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('name'),"required":"","outlined":""},model:{value:(_vm.localEntity.name),callback:function ($$v) {_vm.$set(_vm.localEntity, "name", $$v)},expression:"localEntity.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuStart",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.localEntity.start,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.localEntity, "start", $event)},"update:return-value":function($event){return _vm.$set(_vm.localEntity, "start", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('start'),"prepend-icon":"","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","outlined":""},model:{value:(_vm.localEntity.start),callback:function ($$v) {_vm.$set(_vm.localEntity, "start", $$v)},expression:"localEntity.start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[(_vm.menuStart)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuStart.save(_vm.localEntity.start)}},model:{value:(_vm.localEntity.start),callback:function ($$v) {_vm.$set(_vm.localEntity, "start", $$v)},expression:"localEntity.start"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.localEntity.end,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.localEntity, "end", $event)},"update:return-value":function($event){return _vm.$set(_vm.localEntity, "end", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('end'),"prepend-icon":"","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","outlined":""},model:{value:(_vm.localEntity.end),callback:function ($$v) {_vm.$set(_vm.localEntity, "end", $$v)},expression:"localEntity.end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[(_vm.menuEnd)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuEnd.save(_vm.localEntity.end)}},model:{value:(_vm.localEntity.end),callback:function ($$v) {_vm.$set(_vm.localEntity, "end", $$v)},expression:"localEntity.end"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('active'),"outlined":""},model:{value:(_vm.localEntity.active),callback:function ($$v) {_vm.$set(_vm.localEntity, "active", $$v)},expression:"localEntity.active"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info","disabled":_vm.saveDisabled},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }